:root {
  --appbar-height: 56px;
  --content-height: calc(100vh - var(--appbar-height));
  font-size: 16px;
}

// Global styles
* {
  font-family: 'GeistSans';
  scrollbar-width: thin;
  scrollbar-color: var(--primary-main) var(--background-default);

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--background-default);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-main);
    border-radius: 10px;
    border: 3px solid var(--background-default);

    &:hover {
      background-color: var(--primary-dark);
    }
  }
}

// Google reCAPTCHA
.grecaptcha-badge {
  visibility: hidden !important;
}