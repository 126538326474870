:root{--appbar-height: 56px;--content-height: calc(100vh - var(--appbar-height));font-size:16px}*{font-family:"GeistSans";scrollbar-width:thin;scrollbar-color:var(--primary-main) var(--background-default)}*::-webkit-scrollbar{width:12px;height:12px}*::-webkit-scrollbar-track{background-color:var(--background-default);border-radius:10px}*::-webkit-scrollbar-thumb{background-color:var(--primary-main);border-radius:10px;border:3px solid var(--background-default)}*::-webkit-scrollbar-thumb:hover{background-color:var(--primary-dark)}.grecaptcha-badge{visibility:hidden !important}
@font-face {
font-family: 'GeistSans';
src: url(/_next/static/media/e11418ac562b8ac1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: 'GeistSans Fallback';src: local("Arial");ascent-override: 85.83%;descent-override: 20.52%;line-gap-override: 9.33%;size-adjust: 107.19%
}.__className_3a0388 {font-family: 'GeistSans', 'GeistSans Fallback'
}.__variable_3a0388 {--font-geist-sans: 'GeistSans', 'GeistSans Fallback'
}

